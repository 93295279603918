import { isEmpty } from 'lodash';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { AssetListResponse } from '../types';

export const useLockAction = (isAttributeLocked: boolean) => {
  const client = createHttpClient();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ attributeId }: { attributeId: number; onError: () => void }) =>
      client({
        url: isAttributeLocked
          ? END_POINTS.BRAND_LIBRARY.UNLOCK_ATTRIBUTE(attributeId)
          : END_POINTS.BRAND_LIBRARY.LOCK_ATTRIBUTE(attributeId),
        method: HttpMethod.PATCH,
      }),
    {
      onError: (error: Error, variables) => {
        showAlert({
          type: AlertType.ERROR,
          header: `Failed to ${isAttributeLocked ? 'unlock' : 'lock'} attribute`,
          message: `${error?.message || 'Request failed'}`,
        });
        if (variables?.onError) {
          variables.onError();
        }
      },
      onSuccess: (_, variables) => {
        const cache = queryClient.getQueryData<InfiniteData<AssetListResponse>>(
          QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST,
        );
        if (isEmpty(cache?.pages)) {
          return;
        }
        const updatedPages = cache?.pages?.map(page => {
          const updatedItems = page.items.map(item => {
            if (item.id === variables.attributeId) {
              return {
                ...item,
                isLocked: !isAttributeLocked,
              };
            }

            return item;
          });

          return { ...page, items: updatedItems };
        });

        queryClient.setQueryData<InfiniteData<AssetListResponse>>(
          QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST,
          {
            ...cache,
            pages: updatedPages,
          },
        );

        showAlert({
          type: AlertType.SUCCESS,
          message: `Asset ${isAttributeLocked ? 'unlocked' : 'locked'} successfully!`,
        });
      },
    },
  );

  return mutation;
};
