import { FC, useState } from 'react';

import { Cross, ZoomIn, ZoomOut } from '@assets/icons';
import { Modal, Spinner, VideoPlayer } from '@components/index';
import {
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  ZOOM_STEP,
} from '@pages/home-page/constants';

interface ImagePreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  url: string;
  externalAssetId?: string;
  isVideoPreview?: boolean;
}

const ImageVideoPreviewModal: FC<ImagePreviewModalProps> = ({
  isOpen,
  onClose,
  fileName,
  url,
  externalAssetId,
  isVideoPreview = false,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = (e: React.PointerEvent<Element>) => {
    e.stopPropagation();
    setZoomLevel(prev => Math.min(prev + ZOOM_STEP, MAX_ZOOM_LEVEL));
  }; // Limit max zoom level

  const handleZoomOut = (e: React.PointerEvent<Element>) => {
    e.stopPropagation();
    setZoomLevel(prev => Math.max(prev - ZOOM_STEP, MIN_ZOOM_LEVEL));
  }; // Limit min zoom level

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="flex flex-col w-[85vw] h-[90vh] pointer-events-auto overflow-auto">
        <div className="flex flex-row p-4 border-b justify-between items-center h-22 gap-2">
          <div className="flex justify-start items-center gap-2 text-md font-semibold">
            <div className="break-all">{fileName}</div>
            {externalAssetId && (
              <div className="flex items-center text-sm text-blue-2 border-blue-3 bg-blue-4 rounded-xl p-2 mt-1 font-semibold w-fit max-w-[200px] whitespace-nowrap">
                {`TAB ID: ${externalAssetId}`}
              </div>
            )}
          </div>

          <div className="flex gap-4 items-center">
            {!isVideoPreview && (
              <>
                <ZoomIn
                  className="cursor-pointer"
                  onPointerDown={handleZoomIn}
                />
                <ZoomOut
                  className="cursor-pointer"
                  onPointerDown={handleZoomOut}
                />
                <div className="border-r border-gray-6 h-7" />
              </>
            )}
            <Cross
              className="cursor-pointer text-gray-14 h-6 w-6"
              onPointerDown={(e: React.PointerEvent<Element>) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
        </div>
        {isVideoPreview ? (
          <div className="flex flex-1 items-center justify-center h-[90%]">
            {url ? (
              <VideoPlayer url={url} />
            ) : (
              <div>
                <div className="text-xl font-medium mb-2">
                  Your video is being processed. Please wait for a few minutes.
                </div>
                <Spinner />
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-1">
            <div className="flex flex-col w-full h-[calc(90vh-100px)] items-center overflow-auto my-2">
              {url && (
                <img
                  src={url}
                  alt="Selected image preview"
                  className="w-[55%] h-full border shadow-lg overflow-auto object-contain transition-transform duration-300 ease-in-out"
                  style={{
                    transform: `scale(${zoomLevel})`,
                    transformOrigin: `${zoomLevel < 1 ? 'center' : 'top'}`,
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImageVideoPreviewModal;
