import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { FolderEmpty } from '@assets/icons';
import { Spinner } from '@components/index';
import { ASSET_INFO } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { DataItem, GroupedKnowledgeItems } from '@pages/home-page/types';
import { workspace } from '@store/atoms/workspace';

import AssetItems from './asset-items/AssetItems';
import DocumentItems from './document-items/DocumentItems';
import KnowledgeAttributeItems from './knowledge-attributes/KnowledgeAttributeItems';

interface AssetDataItemsProps {
  dataItems: DataItem[];
  isLoading?: boolean;
  assetType: string;
  imageClassName: string;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  knowledgeDataItems?: GroupedKnowledgeItems;
  isFilteredKnowledgeDataItems?: boolean;
}

const AssetDataItems: FC<AssetDataItemsProps> = ({
  dataItems,
  isLoading,
  imageClassName,
  assetType,
  handleDelete,
  handleEdit,
  knowledgeDataItems,
  isFilteredKnowledgeDataItems,
}) => {
  const currentWorkspace = useRecoilValue(workspace);

  if (isLoading) {
    return (
      <tr>
        <td colSpan={4} className="text-center pb-3">
          <Spinner />
        </td>
      </tr>
    );
  }

  if (
    dataItems.length === 0 &&
    assetType !== ASSET_INFO[AssetType.KNOWLEDGE].name
  ) {
    const formattedAssetType = assetType.slice(0, -1).toLowerCase();

    return (
      <tr>
        <td colSpan={4} className="text-center py-8">
          <div className="flex flex-col items-center justify-center gap-1">
            <FolderEmpty />
            <p className="text-sm text-dark-3 font-semibold">File Empty</p>
            <p className="text-sm text-dark-3">
              {`Seems like no ${formattedAssetType} has been added to the library`}
            </p>
          </div>
        </td>
      </tr>
    );
  }

  if (assetType === ASSET_INFO[AssetType.KNOWLEDGE].name)
    return (
      <KnowledgeAttributeItems
        knowledgeDataItems={knowledgeDataItems as GroupedKnowledgeItems}
        workspaceId={currentWorkspace.id}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        isFiltered={isFilteredKnowledgeDataItems}
      />
    );

  if (assetType === ASSET_INFO[AssetType.RAW_FILE].name)
    return (
      <DocumentItems
        dataItems={dataItems}
        workspaceId={currentWorkspace?.id}
        handleDelete={handleDelete}
      />
    );

  return (
    <AssetItems
      dataItems={dataItems}
      imageClassName={imageClassName}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
    />
  );
};

export default AssetDataItems;
