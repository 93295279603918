import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { AssetType } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';
import { workspace } from '@store/atoms/workspace';

import AssetRow from './AssetRow';

interface AssetItemsProps {
  dataItems: DataItem[];
  imageClassName: string;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
}

const AssetItems: FC<AssetItemsProps> = ({
  dataItems,
  imageClassName,
  handleDelete,
  handleEdit,
}) => {
  const currentWorkspace = useRecoilValue(workspace);

  const groupedItems = useMemo(() => {
    return dataItems.reduce(
      (acc: { inheritedItems: DataItem[]; ownItems: DataItem[] }, item) => {
        if (item.parentClientId !== currentWorkspace.id) {
          acc.inheritedItems.push(item);
        } else {
          acc.ownItems.push(item);
        }

        return acc;
      },
      { inheritedItems: [], ownItems: [] },
    );
  }, [dataItems, currentWorkspace.id]);

  return (
    <tr>
      <td colSpan={4} className="px-2">
        {groupedItems.inheritedItems.length > 0 && (
          <div className="flex bg-lightest py-2 rounded mb-2 border border-gray-6">
            <div className="flex-1 px-2 py-1">
              {groupedItems.inheritedItems.map(item => (
                <AssetRow
                  key={item.id}
                  dataItem={item}
                  imageClassName={imageClassName}
                  currentWorkspaceId={currentWorkspace.id}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  isGrouped={true}
                />
              ))}
            </div>
          </div>
        )}
        {groupedItems.ownItems.map(item => (
          <AssetRow
            key={item.id}
            dataItem={item}
            imageClassName={imageClassName}
            currentWorkspaceId={currentWorkspace.id}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        ))}
      </td>
    </tr>
  );
};

export default AssetItems;
