import { ChangeEvent, FC, useEffect, useRef } from 'react';

interface VideoProgressBarProps {
  videoRef: React.MutableRefObject<HTMLVideoElement>;
}

const VideoProgressBar: FC<VideoProgressBarProps> = ({ videoRef }) => {
  const barRef = useRef<HTMLInputElement>();

  // The animate function runs repeatedly, updating the progress bar based on the current video time
  useEffect(() => {
    let af: number;
    const animate = () => {
      const progress =
        (videoRef.current?.currentTime / videoRef.current?.duration) * 100;
      if (barRef.current) {
        barRef.current.value = `${progress}`;
      }

      af = requestAnimationFrame(animate);
    };
    animate();

    // Cleanup function to cancel the animation frame when the component unmounts or videoRef changes
    return () => cancelAnimationFrame(af);
  }, [videoRef]);

  const seek = (e: ChangeEvent<HTMLInputElement>) => {
    if (!videoRef.current) {
      return;
    }

    try {
      const value = e.target.value;
      videoRef.current.currentTime =
        (Number(value) * videoRef.current.duration) / 100;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <input
      ref={barRef as React.MutableRefObject<HTMLInputElement>}
      type="range"
      step="0.01"
      min="0"
      max="100"
      onChange={seek}
      className="video_progress-bar absolute left-[calc(5%)] bottom-[10px] cursor-pointer"
    />
  );
};

export default VideoProgressBar;
