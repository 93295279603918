export const END_POINTS = {
  AUTH: {
    LOGIN: '/auth/login',
    SSO_LOGIN: '/auth/sso_config',
    FORGOT_PASSWORD: '/auth/forgot',
    RESET_PASSWORD: '/auth/reset',
    LOGOUT: '/auth/logout',
  },
  USER: {
    GET_USER_DETAILS: '/user',
    GET_USER_LIST: (workspaceId: number) => `/client/${workspaceId}/users`,
    INVITE_USER: (workspaceId: number) => `client/${workspaceId}/invite`,
    DELETE_USER: (userId: number, workspaceId: number) =>
      `/user/${userId}/client/${workspaceId}/deactivate`,
    PERMISSIONS: '/user/permissions',
  },
  WORKSPACE: {
    LIST_WORKSPACE: '/client/list',
    SELECT_CLIENT: (userId: number | null, clientUuid: string) =>
      `/user/${userId}/client/${clientUuid}/select`,
    BASE_WORKSPACE: '/client',
    GET_ALL_WORKSPACE: '/client/v2/list',
    ACTIVATE_CLIENT: (clientId: number) => `/client/${clientId}/activate`,
    DEACTIVATE_CLIENT: (clientId: number) => `/client/${clientId}/deactivate`,
    EDIT_CLIENT: (clientId: number) => `/client/${clientId}`,
  },
  BRAND_LIBRARY: {
    GET_PRESIGNED_URL: '/upload/presignedurl',
    CREATE_ASSET: '/asset',
    LIST_ASSETS: '/assets',
    DELETE_ASSET: (assetId: number) => `/asset/${assetId}`,
    EDIT_ASSET: (assetId: number) => `/asset/${assetId}`,
    IMPORT_ASSET_IDS: (clientId: number) => `client/${clientId}/cgl/tab/assets`,
    LOCK_ATTRIBUTE: (id: number) => `/asset/${id}/lock`,
    UNLOCK_ATTRIBUTE: (id: number) => `/asset/${id}/unlock`,
  },
  ROLE: {
    LIST_ROLES: '/roles',
    UPDATE_USER_ROLE: (userId: number, workspaceId: number) =>
      `/client/${workspaceId}/user/${userId}`,
  },
  CHAT: {
    CHAT_REQUEST: '/chat',
  },
  KNOWLEDGE_ATTRIBUTE: {
    GET_KNOWLEDGE_ATTRIBUTE: '/knowledge-attributes',
  },
  HIERARCHY: {
    WORKSPACE_HIERARCHY: (clientId: number) =>
      `/client/${clientId}/workspace-hierarchy`,
    ASSETS_HIERARCHY: (clientId: number) =>
      `/client/${clientId}/assets-hierarchy`,
  },
  CUSTOMER: {
    GET_CUSTOMER: '/customer',
  },
  INTEGRATIONS: {
    GET_INTEGRATION_STATUS: (customerId: number, workspaceId: number) =>
      `customers/${customerId}/integrations/client/${workspaceId}/status`,
    ENABLE_INTEGRATION: (customerId: number, workspaceId: number) =>
      `customers/${customerId}/integrations/client/${workspaceId}/enable`,
    DISABLE_INTEGRATION: (customerId: number, workspaceId: number) =>
      `customers/${customerId}/integrations/client/${workspaceId}/disable`,
  },
};
