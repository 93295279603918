import { useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Settings } from '@assets/icons';
import { Button, LockedButton, PermissionAccess } from '@components/index';
import { GENERAL_CONSTANTS } from '@constants/gereralConstants';
import { UserPermissions } from '@enums/user-permissions';
import { useLogout } from '@hooks/use-logout';
import RoutesPath from '@routes/constants';
import { isSubscriptionAdmin } from '@store/atoms/userPermissions';

const SettingsOption = () => {
  const isSubscriptionAdminUser = useRecoilValue(isSubscriptionAdmin);

  const { mutate: logout } = useLogout();

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      {({ close }) => (
        <>
          <PopoverButton
            ref={
              setReferenceElement as (
                instance: HTMLButtonElement | null,
              ) => void
            }
            as="div"
            className="cursor-pointer z-30"
          >
            <div className="flex flex-row gap-2 items-center">
              <Settings className="size-4 text-gray-14" />
              <span className="text-[15px] font-semibold">
                {GENERAL_CONSTANTS.Settings}
              </span>
            </div>
          </PopoverButton>
          <PopoverPanel
            className="bg-white w-64 shadow-2xl rounded py-2 z-30"
            ref={element => setPopperElement(element as HTMLDivElement | null)}
            style={styles.popper}
            {...attributes.popper}
          >
            <div>
              {isSubscriptionAdminUser && (
                <PermissionAccess
                  permission={UserPermissions.VIEW_WORKSPACES}
                  AllowedContent={
                    <Link to={RoutesPath.WORKSPACE} onClick={close}>
                      <div className="text-dark-1 text-sm flex h-10 px-3 cursor-pointer items-center hover:bg-lightest">
                        {GENERAL_CONSTANTS.Workspaces}
                      </div>
                    </Link>
                  }
                  DeniedContent={
                    <LockedButton
                      buttonText="Workspaces"
                      buttonClassname="w-full pl-3 mb-2"
                    />
                  }
                />
              )}
              <PermissionAccess
                permission={UserPermissions.VIEW_TEAMS}
                AllowedContent={
                  <Link to={RoutesPath.MANAGE_TEAM} onClick={close}>
                    <div className="text-dark-1 text-sm flex h-10 px-3 cursor-pointer items-center hover:bg-lightest">
                      {GENERAL_CONSTANTS.ManageTeam}
                    </div>
                  </Link>
                }
                DeniedContent={
                  <LockedButton
                    buttonText={GENERAL_CONSTANTS.ManageTeam}
                    buttonClassname="w-full pl-3"
                  />
                }
              />
              <PermissionAccess
                permission={UserPermissions.VIEW_INTEGRATIONS}
                AllowedContent={
                  <Link to={RoutesPath.INTEGRATIONS} onClick={close}>
                    <div className="text-dark-1 text-sm flex h-10 px-3 cursor-pointer items-center hover:bg-lightest">
                      {GENERAL_CONSTANTS.Integrations}
                    </div>
                  </Link>
                }
                DeniedContent={
                  <LockedButton
                    buttonText={GENERAL_CONSTANTS.Integrations}
                    buttonClassname="w-full pl-3"
                  />
                }
              />
              <div className="px-3 py-2">
                <Button
                  label="Log out"
                  variant="error"
                  onClick={() => {
                    logout();
                    close();
                  }}
                />
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default SettingsOption;
