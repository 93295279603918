import { FC, useEffect, useRef, useState } from 'react';

import Spinner from '@components/spinner/Spinner';

import VideoPlayButton from './VideoPlayButton';
import VideoProgressBar from './VideoProgressBar';

interface VideoPlayerProps {
  url: string;
  isAutoPlay?: boolean;
}

const VideoPlayer: FC<VideoPlayerProps> = ({ url, isAutoPlay = false }) => {
  const hoverRef = useRef<HTMLDivElement>(null);
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const [isHover, setIsHover] = useState(false);
  const [isPlaying, setIsPlaying] = useState(isAutoPlay);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const node = hoverRef.current;
    if (!node) return;

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    node.addEventListener('mouseenter', handleMouseEnter);
    node.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      node.removeEventListener('mouseenter', handleMouseEnter);
      node.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const onPlayButtonClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoElementRef.current?.pause?.();

      return;
    }
    setIsPlaying(true);
    videoElementRef.current?.play?.();
  };

  return (
    <div className="relative" ref={hoverRef}>
      {isLoading && <Spinner />}
      <video
        ref={videoElementRef}
        autoPlay={isAutoPlay}
        className="rounded max-w-[80vw] max-h-[650px] object-fit"
        controls={false}
        controlsList="nodownload"
        disablePictureInPicture
        muted={false}
        src={url}
        onEnded={() => setIsPlaying(false)}
        onLoadedData={() => setIsLoading(false)}
        preload="auto"
      />

      {isHover && isPlaying && !isLoading && (
        <div className="video_control-background absolute inset-0 rounded">
          <VideoProgressBar
            videoRef={
              videoElementRef as React.MutableRefObject<HTMLVideoElement>
            }
          />
        </div>
      )}

      {(isHover || !isPlaying) && !isLoading && (
        <div className="absolute w-[40px] h-[40px] left-[calc(50%-20px)] top-[calc(50%-20px)] flex items-center justify-center">
          <VideoPlayButton isPlaying={isPlaying} onClick={onPlayButtonClick} />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
