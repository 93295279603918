import { FC, useMemo } from 'react';

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { isEqual } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { UserPermissions } from '@enums/user-permissions';
import { AssetType } from '@pages/home-page/enums';
import { useUpdateAssetsHierarchy } from '@pages/home-page/hooks/use-update-assets-hierarchy';
import { DataItem } from '@pages/home-page/types';
import {
  filterCombinedDocumentItems,
  flattenItems,
  groupDocumentItems,
} from '@pages/home-page/utils';
import { assetsHierarchy } from '@store/atoms/assets-hierarchy';
import { documentList } from '@store/atoms/document-list';
import { userPermissions } from '@store/atoms/userPermissions';

import DraggableAssetRow from '../DraggableAssetRow';

interface DocumentItemsProps {
  dataItems: DataItem[];
  workspaceId: number;

  handleDelete: (id: number, assetType: AssetType) => void;
}

const DocumentItems: FC<DocumentItemsProps> = ({
  dataItems,
  workspaceId,
  handleDelete,
}) => {
  const permissions = useRecoilValue(userPermissions);
  const [documentItems, setDocumentItems] = useRecoilState(documentList);
  const setAssetsHierarchy = useSetRecoilState(assetsHierarchy);

  const { mutateAsync: updateAssetHierarchy } = useUpdateAssetsHierarchy();

  const combinedItems = useMemo(
    () => groupDocumentItems(documentItems, workspaceId),
    [documentItems, workspaceId],
  );

  const { isFiltered, filteredCombinedItems } = useMemo(() => {
    // Sort and compare to determine if filtering is needed
    const sortedDataItems = [...dataItems].sort((a, b) => a.id - b.id);
    const sortedDocumentItems = [...documentItems].sort((a, b) => a.id - b.id);
    const filtered = !isEqual(sortedDataItems, sortedDocumentItems);

    // Filter combined items if filtering is required
    const filteredCombined = filtered
      ? filterCombinedDocumentItems(combinedItems, dataItems)
      : combinedItems;

    return { isFiltered: filtered, filteredCombinedItems: filteredCombined };
  }, [dataItems, documentItems, combinedItems]);

  // Get position of document item in the combined list
  const getDocumentPosition = (id: number, items: (DataItem | DataItem[])[]) =>
    items.findIndex(item =>
      Array.isArray(item) ? item[0].id === id : item.id === id,
    );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    // Find original and new positions
    const originalIndex = getDocumentPosition(
      active.id as number,
      combinedItems,
    );
    const newIndex = getDocumentPosition(over.id as number, combinedItems);

    if (originalIndex === -1 || newIndex === -1) return;

    // Move items and flatten for state and API update
    const reorderedItems = arrayMove(combinedItems, originalIndex, newIndex);
    const flattenedItems = flattenItems(reorderedItems);

    setDocumentItems(flattenedItems);

    const documentsHierarchy = reorderedItems.flat().map(item => item.id);
    const response = await updateAssetHierarchy({
      clientId: workspaceId,
      payload: { documentsHierarchy },
      onSuccess: () => {
        setAssetsHierarchy(prev => ({
          ...prev,
          documentsHierarchy,
        }));
      },
    });

    if (!response) setDocumentItems(flattenItems(combinedItems));
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
      <SortableContext items={dataItems} strategy={verticalListSortingStrategy}>
        {filteredCombinedItems.map(item => (
          <DraggableAssetRow
            key={Array.isArray(item) ? `group-${item[0].id}` : item.id}
            item={item}
            handleDelete={handleDelete}
            isGrouped={Array.isArray(item)}
            isDraggable={
              !isFiltered &&
              permissions.includes(UserPermissions.MANAGE_BRAND_LIBRARY)
            }
          />
        ))}
        <DragOverlay>{null}</DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default DocumentItems;
